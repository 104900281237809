<template>
	<div class="container">
		<div class="abstract">
			<img src="../../assets/imgs/smart.png" alt="">
			<div class="text">
				<p class="title">城市更新</p>
				<p class="subTitle">urban renewal</p>
			</div>
		</div>
		
		<div class="smart-greenhouse">
			<div class="text">
				<div class="line"></div>
				<p class="title">智慧管廊</p>
				<p class="subTitle">Smart greenhouse</p>
			</div>
			
			<div class="under">
				<div class="top">
					<p class="title">城市地下综合管廊</p>
					<p class="subTitle">Urban underground comprehensive pipe gallery</p>
					<p class="content">城市地下管道综合走廊又称“共同沟”就是在城市的地下建造一个隧道空间，将电力、通信，燃气、供热、给排水等各种工程管线集于一体，是保障城市运行的重要基础设施和“生命线”</p>
				</div>
				
				<img src="../../assets/imgs/gw1.png" alt="">
			</div>
			
			<div class="design">
				<div class="top">
					<p class="title">智慧管廊设计</p>
					<p class="subTitle">Smart pipe gallery design</p>
				</div>
				<img class="gw2" src="../../assets/imgs/gw2.png" alt="">
			</div>
		</div>
		<div style="text-align: center;background-color: rgb(244, 248, 255);">
			<img class="gw3" src="../../assets/imgs/gw3.png" alt="">
		</div>
		<div class="advantage">
			<img src="../../assets/imgs/gw4.png" alt="">
		</div>
	</div>
</template>

<script>
	export default {
		
	}
</script>

<style scoped>
.abstract {
	position: relative;
}

.abstract img {
	width: 100%;
}

.abstract .text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: #fff;
	text-align: center;
}

.abstract .text .title {
	font-size: 40px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #FFFFFF;
}

.abstract .text .subTitle {
	font-size: 18px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #FFFFFF;
	text-transform: uppercase;
	margin-top: 43px;
}

.text .line {
	width: 25px;
	height: 3px;
	background: #184BAB;
}

.text .title {
	height: 24px;
	font-size: 24px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
	margin: 4px 0;
}

.text .subTitle {
	height: 12px;
	font-size: 12px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #666666;
	text-transform: uppercase;
}

.smart-greenhouse {
	width: 1200px;
	margin: 0 auto;
	box-sizing: border-box;
	padding: 49px 105px 106px;
}

.under {
	margin-top: 18px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.top .title {
	height: 24px;
	font-size: 24px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
}

.top .subTitle {
	height: 12px;
	font-size: 12px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #666666;
	margin-left: 60px;
	text-transform: uppercase;
	margin-top: 4px;
}
.under .content {
	width: 500px;
	font-size: 14px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #666666;
	line-height: 20px;
	margin-top: 55px;
}

.design {
	margin-top: 98px;
}

.gw2 {
	margin-top: 69px;
	margin-left: 104px;
}

.advantage {
	width: 1200px;
	margin: 0 auto;
	box-sizing: border-box;
	padding: 30px 112px 60px;
}

.gw3 {
	width: 1200px;
}
</style>